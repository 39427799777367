.App {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 70%;
  margin: auto;
  text-align: start;
  padding: 50px 200px;
}

.navbar {
  font-size: large;
  text-align: center;
  margin: auto;
  padding: 10px;
  height: 100px;
}

.navbar .navbar-nav {
  display: inline-block;
  height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar img {
  display: inline-block;
  padding-right: 100px;
  width: 100px;
  vertical-align: middle;
}

.navbar .nav-link {
  width: 250px;
  margin: 0 40px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: sans-serif;
  color: #222222;
  font-weight: bolder;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 50px;
}

.main-table {
  width: 90%;
  padding: 50px;
}

.react-bootstrap-table {
  margin: auto;
  margin-bottom: 100px;
  max-width: 100%;
  max-height: 1000px;
  overflow: auto;
}

thead .order {
  margin-right: 10px;
}

thead .react-bootstrap-table-sort-order {
  margin-right: 10px;
}

thead tr {
  height: 60px;
  vertical-align: top;
}

td {
  border-bottom: #cccccc solid 1px !important;
  border-right: #eeeeee solid 1px !important;
}

th {
  padding: 0 50px 0 5px;
  text-align: left;
  border-bottom: #cccccc solid 3px !important;
}

th input {
  width: 100%;
}

td {
  padding: 5px 30px 10px 5px;
  text-align: left;
  min-width: 150px;
  vertical-align: top;
}

.sr-only {
  display: none;
}

.request {
  width: 60%;
}

.navbar-nav {
  max-width: 90vw;
}

.email-page tr {
  height: 35px;
}

.email-page table {
  min-width: 883px;
}

.email-page th {
  padding: 0 50px 0 0;
}

.email-table {
  margin-bottom: 50px;
}

.select-buttons button {
  background-color: white;
  padding: 10px;
  margin: 20px 5px 0px 5px;
}

.select-buttons button:disabled {
  background-color: #dddddd;
}

.logo {
  width: 18px;
  margin-top: -4px;
  vertical-align: middle;
}

.snp {
  width: 12px;
  margin: -3px 2px 0 4px;
}

.green {
  width: 20px;
  margin: -2px 0 0 0;
}

.labour {
  width: 16px;
  margin: -4px 2px 0px 2px;
}

.libdem {
  width: 24px;
  margin: -6px -3px -5px -2px;
}

.ukip {
  width: 15px;
  margin-top: -4px;
  margin-left: 2px;
  margin-right: 1px;
}

.constituency-list {
  padding-top: 20px;
  max-height: 750px;
  max-width: 100%;
  overflow: auto;
}

.indyscot {
  width: 15px;
  margin: -4px 2px 0px 2px;
}

.alba {
  width: 17px;
  margin: -4px 2px 0px 2px;
}

.libertarian {
  width: 16px;
  margin-left: 1px;
  margin-right: 1px;
}

.reform {
  width: 16px;
  margin: -4px 2px 0px 2px;
}

.igv {
  width: 15px;
  margin: -4px 2px 0px 2px;
}

.freedom {
  width: 15px;
  margin: -4px 2px 0px 2px;
}

.abolish {
  width: 15px;
}

.reclaim {
  width: 15px;
  margin-top: -2px;
  margin-left: 2px;
  margin-right: 2px;
}

.a4u {
  width: 16px;
  margin-left: 2px;
  margin-right: 1px;
}

.liberal {
  width: 16px;
  margin-left: 3px;
  margin-right: 2px;
}

.wep {
  width: 15px;
  margin-left: 2px;
  margin-right: 1px;
}

.candidates {
  white-space: nowrap;
  min-height: 25px;
  margin-bottom: 20px;
}

.candidates li {
  display: inline-block;
  white-space: pre-wrap;
  vertical-align: top;
}

.headers {
  margin-top: 20px;
  margin-left: 80px;
  min-width: 1000px;
}

.headers div {
  display: inline-block;
  white-space: pre-wrap;
  vertical-align: top;
  font-weight: bold;
}

.cand-name {
  width: 180px;
}

.cand-party {
  width: 250px;
}

.cand-con {
  width: 200px;
}

.cand-rr {
  width: 90px;
}

.cand-support {
  width: 100px;
}

.cand-stat {
  max-width: 600px;
}

.tick {
  width: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
}

.pledged-party-text {
  color: rgb(6, 168, 0);
}

.party-pledge-header {
  width: 1000px;
  text-align: center;
  font-weight: bolder;
}

.party-pledges {
  width: 1000px;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
}

.party-pledges div {
  display: inline-block;
  width: 200px;
}

.party-pledges p {
  width: 130px;
  display: inline-block;
  margin: 3px 5px 0 5px;
  vertical-align: top;
}

.party-pledges img {
  display: inline-block;
  width: 50px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.pledged img {
  -webkit-filter: none;
  filter: none;
}

.pledged p {
  font-weight: bold;
  color: rgb(6, 168, 0);
}

.snp-pledge img {
  width: 28px;
  margin: 5px;
  margin-top: 10px;
}

.labour-pledge img {
  width: 40px;
  margin: 5px;
}

@media (max-width: 1750px) {
  .request {
    width: 70%;
  }
}

@media (max-width: 1550px) {
  .App {
    padding: 50px 100px;
  }

  .main-table {
    width: 90%;
  }
}

@media (max-width: 1610px) {
  .request {
    width: 80%;
  }

  .App {
    padding: 50px 50px;
  }

  .nav-link {
    display: inline-block;
    margin: 0 !important;
  }

  .navbar-nav {
    width: 250px;
    vertical-align: top;
    height: auto !important;
  }

  .nav-link {
    text-align: left;
  }

  .navbar img {
    padding-top: 40px;
  }

  .App {
    padding-top: 50px;
  }
}

@media (max-width: 970px) {
  .navbar img {
    padding-right: 50px;
    padding-left: 50px;
  }

  .App {
    width: 90%;
  }
}

@media (max-width: 750px) {
  .email-page tr {
    height: 70px;
  }

  .email-page table {
    width: 483px;
    min-width: 483px;
  }

  .email-page th {
    padding: 0;
  }

  .email-table {
    height: 180px;
  }
}

@media (max-width: 503px) {
  .request {
    width: 90%;
  }

  .App {
    padding-top: 150px;
  }

  .nav-link {
    text-align: center;
  }

  .navbar img {
    padding-top: 0;
  }
}

@media (max-width: 450px) {
  .App {
    padding-left: 20px;
    padding-right: 20px;
  }
}
